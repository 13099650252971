//framework
html {
  font-size: 62.5%;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  &[data-whatinput="mouse"] a:focus,
  &[data-whatinput="mouse"] input:focus,
  &[data-whatinput="mouse"] button:focus,
  &[data-whatinput="mouse"] .selection:focus,
  &[data-whatinput="mouse"] .select2-container--focus:focus,
  &[data-whatinput="mouse"] .select2-selection:focus {
    outline: none;
  }

  body {
    position: relative;
    max-width: 100vw;
    overflow-x: hidden;
    margin: 0;
    width: 100%;
  }

  a:focus, a:hover {
    text-decoration: none;
  }

  ul, ol {
    margin-top: 0;
  }

  .flex-row {
    display: flex;
    flex-wrap: wrap;

    &::before, &::after {
      width: 0;
    }
  }

  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flex-end {
    display: inline-flex;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .relative {
    position: relative;
  }

  img {
    max-width: 100%;
  }

  svg {
    max-width: 100%;
    max-height: 100%;
  }

  figure {
    margin: 0;
  }

  button {
    cursor: pointer;
    font-family: inherit;
    padding: 0;
    background: transparent;
    border: none;
    appearance: none;
  }

  input:not([class^=able] input) {
    appearance: none;
  }
}

//accordeons

.accordeon-toggle {
  cursor: pointer;
}

.accordeon-content {
  overflow: hidden;
  transition: all .2s ease-in-out;
}

//onglets
.onglet-content {
  &:not(.active) {
    display: none !important;
  }
}

.unloaded {
  position: absolute;
  opacity: 0;
	visibility: hidden;
}


//modals
.modals {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 0;
	visibility: hidden;
  pointer-events: none;
  transition: all .2s ease-in-out;

  &.modal-visible {
    opacity: 1;
		visibility: visible;
    pointer-events: auto;
  }

  .modal-closer {
    height: 100%;
    width: 100%;
    background: rgba(#000, .4);

  }

  .modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
		visibility: hidden;
    pointer-events: none;
    transition: all .2s ease-in-out;

    &.modal-visible {
      opacity: 1;
			visibility: visible;
      pointer-events: auto;
    }
  }
}

//système de grille
.container-wide {
  margin: 0 auto;
  width: calc(100vw - 2 * #{$min-side});
  @include above(xs) {
    width: calc(100vw - 2 * #{$gap});
  }
}

.container {
  margin: 0 auto;
  width: $baseWidth;
  max-width: calc(100vw - 2 * #{$min-side});
  @include above(xs) {
    max-width: calc(100vw - 2 * #{$gap});
    width: $xsWidth;
  }
  @include above(sm) {
    width: $smWidth;
  }
  @include above(md) {
    width: $mdWidth;
  }
  @include above(lg) {
    width: $lgWidth;
  }
  @include above(xl) {
    width: $xlWidth;
  }
}

.container-fluid {
  width: $baseWidth;
}

//--//flex-control
.flex-container {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + #{$min-side});
  margin-top: calc(#{$vert-gap} * -1);
  @include above(xs) {
    width: calc(100% + #{$gap});
  }

  &.flex-space {
    justify-content: space-between;
  }

  @for $i from 1 through $columns {
    > .xxs-#{$i} {
      margin-top: $vert-gap;
      margin-right: $min-side;
      width: calc((100% / #{$columns}) * #{$i} - #{$min-side});
      @include above(xxs) {
        margin-right: $gap;
        width: calc((100% / #{$columns}) * #{$i} - #{$gap});
      }
    }
    > .xxs-offset-#{$i} {
      margin-left: calc((100% / #{$columns}) * #{$i});
    }
  }

  @for $i from 1 through $columns {
    > .xs-#{$i} {
      @include above(xs) {
        margin-right: $gap;
        width: calc((100% / #{$columns}) * #{$i} - #{$gap});
      }
    }
    > .xs-offset-#{$i} {
      margin-left: calc((100% / #{$columns}) * #{$i});
    }
  }

  @include above(sm) {
    @for $i from 1 through $columns {
      > .sm-#{$i} {
        margin-top: $vert-gap;
        margin-right: $gap;
        width: calc((100% / #{$columns}) * #{$i} - #{$gap});
      }
      > .sm-offset-#{$i} {
        margin-left: calc((100% / #{$columns}) * #{$i});
      }
    }

    > .sm-offset-0 {
      margin-left: 0;
    }
  }
  @include above(md) {
    @for $i from 1 through $columns {
      > .md-#{$i} {
        margin-top: $vert-gap;
        margin-right: $gap;
        width: calc((100% / #{$columns}) * #{$i} - #{$gap});
      }
      > .md-offset-#{$i} {
        margin-left: calc((100% / #{$columns}) * #{$i});
      }
    }

    > .md-offset-0 {
      margin-left: 0;
    }
  }
  @include above(lg) {
    @for $i from 1 through $columns {
      > .lg-#{$i} {
        margin-top: $vert-gap;
        margin-right: $gap;
        width: calc((100% / #{$columns}) * #{$i} - #{$gap});
      }
      > .lg-offset-#{$i} {
        margin-left: calc((100% / #{$columns}) * #{$i});
      }
    }

    > .lg-offset-0 {
      margin-left: 0;
    }
  }
  @include above(xl) {
    @for $i from 1 through $columns {
      > .xl-#{$i} {
        margin-right: $gap;
        width: calc((100% / #{$columns}) * #{$i} - #{$gap});
        margin-top: $vert-gap;
      }
      > .xl-offset-#{$i} {
        margin-left: calc((100% / #{$columns}) * #{$i});
      }
    }

    > .xl-offset-0 {
      margin-left: 0;
    }
  }
  @include above(xxl) {
    @for $i from 1 through $columns {
      > .xxl-#{$i} {
        margin-right: $gap;
        width: calc((100% / #{$columns}) * #{$i} - #{$gap});
        margin-top: $vert-gap;
      }
      > .xxl-offset-#{$i} {
        margin-left: calc((100% / #{$columns}) * #{$i});
      }
    }

    > .xxl-offset-0 {
      margin-left: 0;
    }
  }

  [class^="xs-"],
  [class^="sm-"],
  [class^="md-"],
  [class^="lg-"],
  [class^="xl-"],
  [class^="xxl-"] {
    @include IE {
      margin-right: calc(#{$gap} - 1px) !important;
    }
  }
}

//--//grid control => pas d'offset
.grid-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat($columns, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: $gap;
  grid-row-gap: $vert-gap;
  @for $i from 1 through $columns {
    > .xxs-#{$i} {
      grid-column: auto / span $i;
    }
  }
  @include above(xs) {
    @for $i from 1 through $columns {
      > .xs-#{$i} {
        grid-column: auto / span $i;
      }
    }
  }
  @include above(sm) {
    @for $i from 1 through $columns {
      > .sm-#{$i} {
        grid-column: auto / span $i;
      }
    }
  }
  @include above(md) {
    @for $i from 1 through $columns {
      > .md-#{$i} {
        grid-column: auto / span $i;
      }
    }
  }
  @include above(lg) {
    @for $i from 1 through $columns {
      > .lg-#{$i} {
        grid-column: auto / span $i;
      }
    }
  }
  @include above(xl) {
    @for $i from 1 through $columns {
      > .xl-#{$i} {
        grid-column: auto / span $i;
      }
    }
  }
  @include above(xxl) {
    @for $i from 1 through $columns {
      > .xxl-#{$i} {
        grid-column: auto / span $i;
      }
    }
  }
}
