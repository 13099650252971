//
// Variables initiales
//================================

$gap: 30px;
$vert-gap: 30px;
$columns: 12;
$min-side: 20px;

//breakpoints
$breakpoints: (
	xxs: 446px,
	xs: 576px,
	sm: 768px,
	md: 992px,
	lg: 1200px,
	inter: 1400px,
	xl: 1700px,
	xxl: 1800px,
	xtra: 1850px,
	base: 1920px,
	xxxl: 2199px
);

//largeurs des colonnes
$baseWidth: calc(100vw - 2 * #{$min-side});
$xsWidth: 54rem;
$smWidth: 72rem;
$mdWidth: 96rem;
$lgWidth: 117rem;
$xlWidth: 117rem;

//
// Variables du projet
//================================

//font

$font-text: 'source-sans-pro', sans-serif;
$font-deco: "bebas-neue-pro", sans-serif;


$theme1: #1d1f4b;
$theme2: #dda54c;
$theme3: #d54d30;
$white: #fff;










