
//
//  MEDIA QUERIES
//––––––––––––––––––––––––––––––––––––––––––––––––––
// A map of breakpoints.

@mixin above($breakpoint) {
  @if map-has-key($breakpoints,
          $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media screen and (min-width: $breakpoint-value) {
      @content;
    }
  }
  @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

@mixin below($breakpoint) {
  @if map-has-key($breakpoints,
          $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media screen and (max-width: ($breakpoint-value - 1)) {
      @content;
    }
  }
  @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

@mixin between($lower,
$upper) {
  @if map-has-key($breakpoints,
    $lower) and map-has-key($breakpoints,
    $upper) {
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);
    @media screen and (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }
  }
  @else {
    @if  map-has-key($breakpoints, $lower)==false {
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }
    @if  map-has-key($breakpoints, $upper)==false {
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}

//
// PREFIX
//------------------------------------------

@mixin prefix($property, $val){
  -webkit-#{$property}: $val;
  -moz-#{$property}: $val;
  -ms-#{$property}: $val;
  -o-#{$property}: $val;
  #{$property}: $val;
}


//
// UL LI reset list style type
//------------------------------------------

@mixin no-bullet {
  padding-left: 0;
  margin-bottom: 0;

  li{
    list-style-type: none;
  }
}


//
// IE
//------------------------------------------
@mixin IE {
  @media screen and (min-width:0\0) {
    @content;
  }
}

@mixin IEfigure{
  @media screen and (min-width:0\0) {
    min-width: 100%;
    min-height: 100%;
    height: auto;
    width: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
}

//
// Edge
//------------------------------------------
@mixin edge {
  @supports (-ms-ime-align:auto) {
    @content;
  }
}


