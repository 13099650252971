html {
	width: 100vw;
	
}

body {
	font-family: $font-text;
	font-size: 1.4rem;
	line-height: 1.4;
	font-weight: 400;
	position: relative;
	width: 100%;
	overflow-x: hidden;

	a {
		text-decoration: none;
	}

	ul {
		@include no-bullet;
	}

	.slick-dots li {
		cursor: pointer;
	}

	* {
		font-family: inherit;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	
	.page-structure {
		padding: 3rem 0 10rem;
		@include above(md){
			padding: 6rem 0 20rem;
		}
		
	}

}

img {
	display: block;
}

//utilitaire
.desktop-only {
	@include below(md) {
		display: none !important;
	}
}

.mobile-only {
	@include above(md) {
		display: none !important;
	}
}

.flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.flex-stretch {
	display: flex;
	align-items: stretch;
}

.flex-vertical-center{
	display: flex;
	align-items: center;
}

.svg-wrap{
	flex-shrink: 0;
}


