#main-content {
  width: 100vw;
  min-height: 100vh;
  background: linear-gradient(rgba($theme1, .95)), url(../images/main-bg.jpg) no-repeat center center;
  background-size: cover, cover;
  padding: 5rem 0 6rem;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  @include above(md){
    padding: 12rem 0 13rem;
  }


  .presentation {
    color: $white;
    text-align: center;

    h1 {
      font-size: 6rem;
      line-height: 1.18em;
      opacity: .1;
      font-family: $font-deco;
      margin: 0;
      text-transform: uppercase;
      @include above(xs){
        font-size: 9rem;
      }
      @include above(sm){
        font-size: 12rem;
      }
      @include above(md){
        font-size: 15rem;
      }
      @include above(lg){
        font-size: 19rem;
        line-height: 22.6rem;
      }




    }

    p {
      font-size: 1.5rem;
      line-height: 1.8rem;
      font-weight: 400;
      margin: 15px 0 0;
      @include above(sm){
        font-size: 1.8rem;
        line-height: 2rem;
      }


      strong {
        font-weight: 700;
      }

      &.intro {
        margin-top: -3rem;
        font-size: 1.8rem;
        line-height: 2.3rem;
        @include above(xs){
          margin-top: -5rem;
        }
        @include above(sm){
          margin-top: -6.5rem;
          font-size: 2.2rem;
          line-height: 3rem;
        }
        @include above(md){
          margin-top: -8rem;
        }
        @include above(lg){
          margin-top: -11rem;
        }


        &:after {
          content: "";
          display: block;
          width: 30px;
          height: 2px;
          background-color: $theme3;
          margin: 13px auto 0;
        }
      }

      &:last-child {
        &:after {
          content: "";
          display: block;
          width: 30px;
          height: 2px;
          background-color: $theme3;
          margin: 13px auto 0;
        }
      }
    }

  }

  .card-item {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 2.6rem 3rem;
    border-bottom: 4px solid $theme2;
    height: 100%;
    min-height: 370px;
    position: relative;
    figure{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      &:after{
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: .5;
      }
      img {
        transition: transform .4s linear;
        transform: scale(1.0001);
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .svg-wrap{
      position: relative;
      z-index: 2;
    }
    &:hover{
      figure img{
        transform: scale(1.05);
      }
    }
  }

  @include above(md){

  }

  .deco {
    position: absolute;
    pointer-events: none;
    height: 100%;
    width: 1px;

    &:after, &:before {
      background-color: $white;
      content: "";
      display: block;
      transition: all 1s ease-in-out;
      opacity: .2;
    }

    &.deco-start {
      top: 9px;
      left: 9px;
      @include above(md){
        top: 49px;
        left: 49px;
      }


      &:before {
        width: 100vw;
        height: 1px;
        position: absolute;
        top: 0;
        left: -9px;
        @include above(md){
          left: -49px;
        }

      }

      &:after {
        width: 1px;
        height: 100%;
        position: absolute;
        top: -9px;
        left: 0;
        @include above(md){
          top: -49px;
        }

      }

    }

    &.deco-end {
      bottom: 9px;
      right: 9px;
      @include above(md){
        bottom: 49px;
        right: 49px;
      }


      &:before {
        width: 100vw;
        height: 1px;
        position: absolute;
        bottom: 0;
        right: -9px;
        @include above(md){
          right: -49px;
        }

      }

      &:after {
        width: 1px;
        height: 100%;
        position: absolute;
        bottom: -9px;
        left: 0;
        @include above(md){
          bottom: -49px;
        }
      }
    }
  }

  &.unloaded {
    .deco {
      &:before {
        width: 0;
        opacity: .5;
      }

      &:after {
        height: 0;
        opacity: .5;
      }
    }
  }

}